import firebase from "../config/firebase";

const db = firebase.firestore();

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) {
    return undefined;
  }

  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function listenToCoursesFromFireStore() {
  return db.collection("courses");
}

export function listenToCourseFromFirestore(courseId) {
  return db.collection("courses").doc(courseId);
}

export function listenToUsersFromFireStore() {
  return db.collection("users");
}

export function listenToAssignmentsFromFireStore(courseId) {
  let assignments =  db.collection("courses").doc("NOI").collection("assignments");
  return assignments;
}

export function getUsersFromFirestore() {
  return db.collection("users");
}

export function listenToAssignmentFromFireStore(courseId, assignmentId) {
  return db
    .collection("courses")
    .doc(courseId)
    .collection("assignments")
    .doc(assignmentId);
}

export function addQuestionToFirestore(question) {
  return db
    .collection("courses")
    .doc("Ishopanishad")
    .collection("assignments")
    .doc("assignment1")
    .Add({
      question: question,
    });
}

export function getUserFromFirestore(uid) {
  return db.collection("users").doc(uid);
}

export function setUserProfileData({ user, creds }) {
  if (creds.course === "yes") {
    db.collection("courses")
      .doc("Ishopanishad")
      .update({
        users: firebase.firestore.FieldValue.arrayUnion(user.uid),
      });
  }

  return db.collection("users").doc(user.uid).set({
    displayName: user.displayName,
    email: user.email,
    role: creds.role,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export async function updateCommonFileUrl(downloadUrl, userId, docType){
  
  try {
    const userDoc = db.collection('users').doc(userId);
    userDoc.update({[docType]: downloadUrl})
  } catch (error) {
    throw(error)
  }
}

export async function updateSubmittedFileUrl(
  downloadUrl,
  courseId,
  assignmentId
) {
  const user = firebase.auth().currentUser;

  const submittedAssignment = {
    isGraded: false,
    grade: 0.0,
    submittedFile: downloadUrl,
    userId: user.uid,
    name: user.displayName,
  };

  try {
    const assignment = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment.get();

    var submittedAssignmentsArray = temp.data().submittedAssignments;
    const index = submittedAssignmentsArray.findIndex(
      (assignment) => assignment.userId === user.uid
    );
    const updatedSubmittedAssignments = [...submittedAssignmentsArray];
    updatedSubmittedAssignments[index] = {
      ...updatedSubmittedAssignments[index],
      submittedFile: downloadUrl,
    };

    if (index === -1) {
      db.collection("courses")
        .doc(courseId)
        .collection("assignments")
        .doc(assignmentId)
        .update({
          submittedAssignments: firebase.firestore.FieldValue.arrayUnion(
            submittedAssignment
          ),
        });
    } else {
      db.collection("courses")
        .doc(courseId)
        .collection("assignments")
        .doc(assignmentId)
        .update({
          submittedAssignments: updatedSubmittedAssignments,
        });
    }
  } catch (error) {
    throw error;
  }
}

export async function addToSubmittedAssignment(
  courseId,
  assignmentId,
  values,
  totalGrade,
  studentId
) {
  try {
    const assignment1 = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment1.get();
    var submittedAssignmentsArray = temp.data().submittedAssignments;
    const index = submittedAssignmentsArray.findIndex(
      (assignment) => assignment.userId === studentId
    );

    const updatedSubmittedAssignments = [...submittedAssignmentsArray];

    updatedSubmittedAssignments[index] = {
      ...updatedSubmittedAssignments[index],
      comments: values.comments,
      markingKey: {
        mk1: {
          key: values.mk1,
          score: values.mk1Num,
        },
        mk2: {
          key: values.mk2,
          score: values.mk2Num,
        },
        mk3: {
          key: values.mk3,
          score: values.mk3Num,
        },
        mk4: {
          key: values.mk4,
          score: values.mk4Num,
        },
      },
      grade: totalGrade,
      isGraded: true,
    };

    db.collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId)
      .update({
        submittedAssignments: updatedSubmittedAssignments,
      });
  } catch (error) {
    throw error;
  }
}

export async function addToFinalAssignment(
  courseId,
  assignmentId,
  values,
  // totalGrade,
  // attendanceGrade,
  studentId,
  reviewer,
  // assignment1Grade,
  // assignment2Grade,
  // assignment3Grade,
  // pass
) {
  try {
    const assignment1 = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment1.get();
    var submittedAssignmentsArray = temp.data().submittedAssignments;
    const index = submittedAssignmentsArray.findIndex(
      (assignment) => assignment.userId === studentId
    );

    const updatedSubmittedAssignments = [...submittedAssignmentsArray];

    updatedSubmittedAssignments[index] = {
      ...updatedSubmittedAssignments[index],
      saqComments: values.saqComments,
      vmComments: values.vmComments,
      markingKey: {
        mk1: {
          key: values.mk1,
          score: values.mk1Num,
        },
        mk2: {
          key: values.mk2,
          score: values.mk2Num,
        }
      },
      //grade: totalGrade,
      // pass,
      // assignment1Grade,
      // assignment2Grade,
      // assignment3Grade,
      // attendanceGrade,
      isGraded: true,
      grader: reviewer
    };

    db.collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId)
      .update({
        submittedAssignments: updatedSubmittedAssignments,
      });
  } catch (error) {
    throw error;
  }
}


export async function addToVM(
  courseId,
  assignmentId,
  values,
  studentId,
  reviewer
) {
  try {
    const assignment1 = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment1.get();
    var submittedAssignmentsArray = temp.data().submittedAssignments;
    const index = submittedAssignmentsArray.findIndex(
      (assignment) => assignment.userId === studentId
    );

    const updatedSubmittedAssignments = [...submittedAssignmentsArray];

    updatedSubmittedAssignments[index] = {
      ...updatedSubmittedAssignments[index],
      vmComments: values.vmComments,
      markingKey: {
        mk1: {
          key: values.mk1,
          score: values.mk1Num,
        }
      },
      grade: values.mk1Num,
      isGraded: true,
      grader: reviewer
    };

    db.collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId)
      .update({
        submittedAssignments: updatedSubmittedAssignments,
      });
  } catch (error) {
    throw error;
  }
}

export async function updateSubmittedAssignment(
  courseId,
  assignmentId,
  values,
  totalGrade,
  studentId
) {
  try {
    const assignment1 = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment1.get();
    var submittedAssignmentsArray = temp.data().submittedAssignments;
    const index = submittedAssignmentsArray.findIndex(
      (assignment) => assignment.userId === studentId
    );

    const updatedSubmittedAssignments = [...submittedAssignmentsArray];

    updatedSubmittedAssignments[index] = {
      ...updatedSubmittedAssignments[index],
      comments: values.comments,
      markingKey: {
        mk1: {
          key: values.mk1,
          score: values.mk1Num,
        },
        mk2: {
          key: values.mk2,
          score: values.mk2Num,
        },
        mk3: {
          key: values.mk3,
          score: values.mk3Num,
        },
        mk4: {
          key: values.mk4,
          score: values.mk4Num,
        },
      },
      grade: totalGrade,
      isReviewed: true,
    };

    db.collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId)
      .update({
        submittedAssignments: updatedSubmittedAssignments,
      });
  } catch (error) {
    throw error;
  }
}


export async function updateFinalAssignment(
  courseId,
  assignmentId,
  studentId,
  assignment1,
  assignment2,
  assignment3,
  vm,
  ftSaq,
  grade,  
  pass
) {
  try {    
    const assignment = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment.get();
    var submittedAssignmentsArray = temp.data().submittedAssignments;
    const index = submittedAssignmentsArray.findIndex(
      (assignment) => assignment.userId === studentId
    );

    const updatedSubmittedAssignments = [...submittedAssignmentsArray];

    updatedSubmittedAssignments[index] = {
      ...updatedSubmittedAssignments[index],
      assignment1,
      assignment2,
      assignment3,
      vm,
      ftSaq,
      grade,
      pass
    };

    var updated = await db.collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId)
      .update({
        submittedAssignments: updatedSubmittedAssignments,
      });
    console.log(updated);
  } catch (error) {
    console.log(assignment1);
    console.log(error);
    throw error;
  }
}


export async function publishGradesForAssignment(courseId, assignmentId) {
  try {
    const assignment1 = db
      .collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId);

    var temp = await assignment1.get();
    var publishGrades = temp.data().publishGrades;

    db.collection("courses")
      .doc(courseId)
      .collection("assignments")
      .doc(assignmentId)
      .update({
        publishGrades: !publishGrades,
      });
  } catch (error) {
    throw error;
  }
}

export async function checkIfEmailRegistered(email) {
  const registeredUsersRef = db.collection('2024').doc('registered_users');
  const doc = await registeredUsersRef.get();
  if (doc.exists) {
    const data = doc.data();
    return email in data;
  }
  return false;
}


export async function addRegistrationToFirestore(registrationData) {
  try {
    const isRegistered = await checkIfEmailRegistered(registrationData.email);
    if (isRegistered) {
      throw new Error('This email has already been registered.');
    }
    
    const registeredUsersRef = db.collection('2024').doc('registered_users');
    await registeredUsersRef.set({
      [registrationData.email]: registrationData
    }, { merge: true });
    
    return { success: true, message: 'Registration successful' };
  } catch (error) {
    console.error('Error in addRegistrationToFirestore:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
}

export async function getRegisteredUsersFor2024() {
  const registeredUsersRef = db.collection('2024').doc('registered_users');
  const doc = await registeredUsersRef.get();
  if (doc.exists) {
    const data = doc.data();
    return Object.values(data).map((user, index) => ({
      ...user,
      id: index.toString() // Adding an id field for React keys
    }));
  }
  return [];
}
