import React, { useEffect, useState } from 'react';
import { Container, Header, Table, Loader } from 'semantic-ui-react';
import { getRegisteredUsersFor2024 } from '../../app/firestore/firestoreService';

export default function NewRegistrations() {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const registrationsArray = await getRegisteredUsersFor2024();
        setRegistrations(registrationsArray);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching registrations: ", error);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  if (loading) {
    return <Loader active>Loading Registrations</Loader>;
  }

  return (
    <Container>
      <Header as='h1' textAlign='center' style={{ marginTop: '2em' }}>
        New Registrations for Bhakti-Śāstrī Course 2024
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Initiated Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Reason for Joining</Table.HeaderCell>
            <Table.HeaderCell>Recommending Devotee</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {registrations.map((registration) => (
            <Table.Row key={registration.id}>
              <Table.Cell>{`${registration.firstName} ${registration.lastName}`}</Table.Cell>
              <Table.Cell>{registration.initiatedName}</Table.Cell>
              <Table.Cell>{registration.email}</Table.Cell>
              <Table.Cell>{registration.phoneNumber}</Table.Cell>
              <Table.Cell>{registration.reason}</Table.Cell>
              <Table.Cell>{registration.recommendingDevotee}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  );
}